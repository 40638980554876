.mouse-follower {
    position: fixed;
    width: 70px;
    height:70px;
    /* background-color: rgba(0, 0, 255, 0.5);  */
    /* border-radius: 50%; */
     /* border:1px solid rgb(0, 0, 0); */
     /* padding:10px; */
     /* background-image: url('./images/mouse-followe.jpeg'); */
    pointer-events: none; /* Ensures it doesn't interfere with clicking */
    transform: translate(-50%, -50%); /* Center the circle on the mouse position */
    z-index: 1000; /* Ensure it appears above other content */
    transition: transform 0.2s ease; /* Optional: smooth transition for the size or color */
  }
  