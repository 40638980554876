.logo-image-footer{
  width:140px;
  padding-top:70px;
  padding-left: 170px;
  position:relative;
  left:-110px;
  /* padding-right:220px; */
  top:-40px;
}
.footer-logo{
  width:300px;
}
.footer-logo h1{
  font-size: 25px;
    margin-top: 0px;
    width: 500px;
    padding-left: 60px;
    font-weight: bold;
    background: linear-gradient(to right, yellow, rgb(255, 51, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.footer-whole {
  color: #fff;
  padding: 40px;
  height: auto;
  background-color:#7e84ec;

}
.social-icon{
  text-decoration: none;
  color:white;

}
.twitter:hover{
color:black;

}
.linkedin:hover{
  color:#0077B5;

}
.facebook:hover{
  color:blue;
}
.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* .logo-image-footer {
  max-width: 100px;
} */

.footer-column {
  text-align: center;
  margin-bottom: 0;
  /* padding-left:320px; */
  color: white;
}

.footer-column h3 {
  color:white;
}
.footer-column-1 {
  text-align: center;
  margin-bottom: 0;
  padding-right:10px;
  color:white;
}
.qr{
  width:120px;
}
.footer-column-1 h3 {
  color:white;
}
.footer-column-2 {
  text-align: center;
  margin-bottom: 0;
  /* padding-left:320px; */
  color:white;
}

.footer-column-2 h3 {
  color:white;
  /* padding-bottom: 10px; */
}
.footer-column-3 {
  text-align: center;
  margin-bottom: 0;
  /* padding-left:320px; */
  color:white;
}
.adver{
  /* padding:20px; */
font-weight: 600;
font-size:18px;
}
li{
  cursor:pointer;
}
.footer-list li{
width:300px;
}
.width{
  width:150px !important;
}
.footer-flex-1{
  display:flex;
}.footer-column-3 h3 {
  color:white;
}
 .a
{
  text-decoration: none;
  color:white;
}
.footer-list {
  list-style: none;
  padding: 0;
}

.footer-link {
  color: #fede6c;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  margin-top: 0;
  border-top: 1px solid #444;
  padding-top: 10px;
  position: relative;
  top: 0px;
}

.footer-bottom p {
  color: white;
}
@media screen and (max-width: 768px) {
  .logo-image-footer {
    width: 290px;
    position: relative;
    top: -80px;
  }

  /* Adjust padding and alignment for smaller screens */
  .footer-whole {
    padding: 20px;
    height: auto;
  }

  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin-bottom: 20px;
  }

  .footer-bottom {
    top: -60px;
  }
}

@media screen and (max-width: 480px) {
  .logo-image-footer {
    width: 150px;
    padding-left:180px;
    margin:0 auto;
    top: 0px;
  }
  .footer-logo h1 {
  width:170px;
  font-size: 24px;
        padding-left: 20px;
margin:0 auto;
  
}
  .footer-whole {
    padding: 10px;
    height: auto;
  }

  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin-bottom: 15px;
  } .footer-column-1{
    margin-bottom: 15px;
    /* padding-left:120px; */
  }

  .footer-bottom {
    top: 0px;
  }
}