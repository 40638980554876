.service-final-content {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap; /* Allows wrapping for responsiveness */
  gap: 2rem;
  background-color: #7e84ec;
  /* margin-top:40px; */
  justify-content: center;
  align-items: flex-start;
}
.full{
  background-color: #7e84ec;
}

.service-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  flex: 1;
  max-width: 33.33%; /* 3 columns in a row (100% / 3) */
  box-sizing: border-box; /* Prevents overflow */
}

.service-item {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  height: 350px;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover {
  transform: scale(1.05);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
}

.service-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  z-index: 2;
}

.service-title {
  background:black;
  color:white;
  font-weight: 600;
  padding: 5px 10px;
  /* border:1px solid red; */
  border-radius: 15px;
  font-size: 0.9rem;
  backdrop-filter: blur(5px);
}

.like-button {
  background: rgba(255, 255, 255, 0.9);
  color: #ff004d;
  border: none;
  border-radius: 50%;
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.like-button:hover {
  background: #ff004d;
  color: #fff;
  transform: scale(1.1);
}

.service-video {
  width: 100%; /* Make the video fill the container's width */
  height: 100%; /* Make the video fill the container's height */
  object-fit: cover; /* Ensures the video covers the entire container */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 15px;
}

.service-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 25px;
  background: linear-gradient(to top, rgb(0, 0, 0), transparent);
  color: #fff;
  text-align: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 2;
}

.service-description {
  font-size: 1rem;
  margin: 0;
}

@media (max-width: 768px) {
  .service-column {
    max-width: 100%; /* Full-width columns on smaller screens */
  }

  /* Adjust layout for mobile screens, stacking the columns */
  .service-final-content {
    /* flex-direction: column;
    gap: 1.5rem; */
    padding: 2rem;
  display:flow-root;
  flex-wrap:wrap; /* Allows wrapping for responsiveness */
  gap: 1rem;
  background-color: #7e84ec;
  /* margin-top:40px; */
  justify-content:space-evenly;
  align-items: flex-start;
  }
}
