/* Contact.css */
/* General Styles */
.contact-container {
  min-height: 100vh;
  background: #f8f9fa;
}

/* Header Section */


.about-header h1 {
  font-size: 2.5rem;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Contact Section */
.contact-section {
  padding: 4rem 0;
  background-color:  #7e84ec;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.contact-intro {
  font-size: 1.25rem;
  line-height: 1.75;
  color: #fbfdff;
  text-align: center;
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Form Section */
.contact-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

.contact-form {
  background: white;
  padding: 2.5rem;
  border-radius: 1rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 1.875rem;
  color: #1f2937;
  margin-bottom: 2rem;
  font-weight: 600;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  font-size: 0.875rem;
  color: #374151;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-input,
.form-textarea {
  width: 90%;
  padding: 0.875rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
  border-color: #6366f1;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
}

.form-textarea {
  resize: vertical;
  min-height: 120px;
}

.form-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.submit-button {
  background: #6366f1;
  color: white;
  padding: 0.875rem 1.75rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: background 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
  background: #4f46e5;
  transform: translateY(-1px);
}

.clear-button {
  background: #ef4444;
  color: white;
  padding: 0.875rem 1.75rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: background 0.3s ease, transform 0.2s ease;
}

.clear-button:hover {
  background: #dc2626;
  transform: translateY(-1px);
}

/* Locations Section */
.locations-section {
  background: white;
  padding: 2.5rem;
  border-radius: 1rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.locations-title {
  font-size: 1.875rem;
  color: #1f2937;
  margin-bottom: 2rem;
  font-weight: 600;
}

.locations-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.location-card {
  background: #f9fafb;
  border-radius: 0.75rem;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.location-card:hover {
  transform: translateY(-3px);
}

.location-map {
  width: 100%;
  height: 200px;
  border: 0;
}

.location-title {
  font-size: 1.25rem;
  color: #1f2937;
  padding: 1.5rem 1.5rem 0;
  font-weight: 600;
}

.location-address {
  color: #6b7280;
  padding: 1rem 1.5rem 1.5rem;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .contact-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .contact-form,
  .locations-section {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .about-header h1 {
    font-size: 2rem;
  }

  .contact-intro {
    font-size: 1.1rem;
  }

  .form-title,
  .locations-title {
    font-size: 1.5rem;
  }

  .form-buttons {
    flex-direction: column;
  }

  .submit-button,
  .clear-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 1rem;
  }

  .contact-form,
  .locations-section {
    padding: 1.5rem;
  }

  .location-map {
    height: 160px;
  }
}