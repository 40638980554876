/* *{
    margin:0;
    padding: 0;
}
.nav{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    padding: 30px;
}
.nav-list li{
    text-decoration: none;
}
.logo-img{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;  
}
.logo-img{
    height: 150px;
    width: 150px;
}
.logo{
    position: relative;
    right: 20%;
}
.name{
    position: relative;
    right:50%;
}






.navbar ul{
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
}
.nav-item{
    list-style: none;
}
 .nav-link{
    text-align: center;
    justify-content: space-between;
    padding: 20px;
  
} */
/* Navbar container */
navbar{
  /* position:sticky; */
  /* width:97%; */
  /* z-index:-1px; */
  /* z-index:22; */
  /* height:120px; */
}
.tagline-navbar{
  background-color: #f4d3c4;

}


.google_translate_element{
  position: absolute; top: 20px; right:500px;
}

.tag-navbar{
  /* height:200px; */
  /* padding-bottom:10px; */
  background-color: #070d2b;
  position: relative;
  color:white;
  /* border: 2px solid black; */
  padding:4px 0px 4px 0px;
  margin:0px;

  /* top:-20px; */
font-size:16px;
}
.nav {
  display: flex;
 /* position:sticky; */
 /* top:0px; */
 z-index:22;
 
  justify-content: space-between;
  align-items: center;
  padding:0px 20px;
  /* margin-bottom: 520px; */
  background-color: #7e84ec;/* Light transparent black */
  color: white;
  font-family: 'Roboto', sans-serif;
  height:80px;
 /* font-size:23px;; */
  }
  
  /* Logo and Name section */
  .logo-name {
    display: flex;
    align-items: center;
    
  }
  .nav-ahref{
    text-decoration: none;
    color:white;
  }
  .logo {
    width:300px;
    padding-left: 30px;
    height:200px;
    /* padding-bottom: 30px; */
    /* background-color: #080d25; */
  }
  
  .logo-img {
    width: 100px;
    padding-left:110px;
    padding-top:70px;
    background-color: #7e84ec;
    height: auto;
  }
  
  .name h1 {
    margin-left: 15px;
    font-size: 24px;
    color: #fff;
  }
  
  /* Navbar items */
  .navbar {
    display: flex;
     cursor: pointer;
    padding-top:40px;
    position:relative;
  /* top:-60px;   */
right:50px;}
  .nav-item:hover{
  
      background: linear-gradient(to right, yellow, rgb(255, 51, 0));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
  }
  .nav-list {
    display: flex;
    list-style: none;
    padding: 0;
    gap:30px;
    font-size: 20px;
  }
  
  .nav-item {
    margin-left: 20px; 
    transition:  3.5s ease-in-out;
  }
  
  .nav-link {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
  }
  
  .nav-link i {
    margin-right: 8px;
  }
  
  /* Hover and active styles */
  .nav-link:hover,
  .nav-link.active {
    color: #f39c12;
  }
  /* Navbar styles */
/* .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #12837f;
  color: #fede6c;
  height: 80px;
} */

/* Mobile view adjustments */
/* .navbar ul {
  display: flex;
  flex-direction: column;
  list-style: none;
} */

/* .nav-item {
  margin: 10px 0;
} */

/* .nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
} */

/* Toggle button, hidden by default */
.toggle-btn {
  display: none;
  /* position: fixed; */
  top: px;
  left: px;
  z-index: 1001;
  height:120px;
  background-color: #7e84ec;
  color: #12837f;
  border: none;
  padding: 0px;
  width:100%;
  cursor: pointer;
  border-radius: 5px;
}
.logo-image{
  /* display:none; */
  position:relative;
  top:-60px;
  /* padding-bottom:50px; */
left:-30px;

  padding-right:290px;
  width:230px;
}


/* .toggle-btn:hover {
  background-color: #0e6b68;
} */

/* Open navbar state for mobile */
/* .nav.open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #12837f;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
} */
/* 
.navbar ul {
  flex-direction: column;
  align-items: center;
} */
.hidden{
  display:none;
}
/* Show toggle button only in mobile view */
@media (max-width: 768px) {
  .toggle-btn {
    display: block;
    width:100%;
    gap: 40px;
  
  }
  /* .logo-navbar{
    display:block;
    height:1200px;
  } */
 .logo h1{
  position:relative;
  width:250px;
  z-index:120;
  top:-90px;
 }
  .nav {
    display: none; 
  }  
.logo-img{display: none;}
  .nav.open {
    display: flex; /* Show navbar when open */
 width:90%;

  }
}

    /* Media query for smaller screens */
@media (max-width: 768px) {
.hidden{
  display:block;
  /* top:200px; */
  /* margin:0px;
  height:0px; */
  /* width:190px; */
  position:relative;
  width:40px;
 
  top:-80px;color:#fede6c; 
  padding-left:300px;
 
  
} .logo-image {
  position: relative;
  top: 20px;
  left: -80px;
  padding-right: 100px;
  width: 80px;
}
  /* Adjust the overall navbar layout */
  .nav {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    /* padding: 10px 20px; */
  }
 
.mouse-follower{
  display: none;
}
  /* Adjust logo size and positioning */
  .logo {
    height:0px;
    width: 180px; /* Decrease logo size */
    margin-bottom: 15px; /* Add spacing between logo and nav items */
  }

  /* Adjust name positioning and font size */
  .name h1 {
    font-size: 20px; /* Decrease font size */
  }

  /* Stack nav items vertically */
  .navbar ul {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0;
  }

  /* Adjust nav items for vertical layout */
  .nav-list {
    flex-direction: column;
    width: 100%;
  }

  /* Center nav items and add spacing */
  .nav-item {
    margin-left: 0;
    padding-left:100px;
    margin-bottom: 10px; /* Add spacing between items */
    width: 100%; /* Full width for nav items */
  }

  /* Adjust nav link font size */
  .nav-link {
    font-size: 16px;
    padding: 10px 0;
    width: 100%; /* Full width for better tap targets */
  }

  /* Adjust tagline-navbar and tag-navbar styles */
  .tagline-navbar {
    font-size: 14px; /* Smaller text size for the tagline */
  }

  .tag-navbar {
    font-size: 14px;
    padding: 6px 0;
  }

}
