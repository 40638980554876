
  /* background-color: #34b8d8; */
/* *{
    margin:0;
    padding: 0;
}
.nav{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    padding: 30px;
}
.nav-list li{
    text-decoration: none;
}
.logo-img{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;  
}
.logo-img{
    height: 150px;
    width: 150px;
}
.logo{
    position: relative;
    right: 20%;
}
.name{
    position: relative;
    right:50%;
}






.navbar ul{
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
}
.nav-item{
    list-style: none;
}
 .nav-link{
    text-align: center;
    justify-content: space-between;
    padding: 20px;
  
} */
  .parallax-section {
    /* Set the background image */
  
    background-image: url('./images/dark-bg-image.jpg');
    /* Replace with your image path */
    background-size: cover; /* Ensure the image covers the entire section */
    background-attachment: fixed; /* Makes the image fixed while content scrolls */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    
    /* Set the height of the section */
    height: 100vh; /* Full viewport height for the parallax effect */
    width: 100%;
    position: relative; /* Ensure the section stays relative to the page */
  }
  
  .content {
    padding: 50px;
    font-size:24px;
    background-color:transparent; /* Optional: adds a slight background color to content */
    color:white;
  }
  
/* Navbar container */
.world-content{
  position:relative;
left:750px;
width:40%;
  top:-300px;
}.world-content button {
  padding: 20px;
  color: black;
  border: 1px solid black;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition */
}

.world-content button:hover {
  color: white;
  background-color: black;
}


.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 20px;
    background-color: #333;
    color: #fff;
    font-family: 'Roboto', sans-serif;
  
  }
  
  /* Logo and Name section */
  .logo-name {
    display: flex;
    align-items: center;
  }
  
  .logo {
    width: 50px;
    height: 50px;
  }
  
  .logo-img {
    width: 100%;
    height: auto;
  }
  
  /* .name h1 {
    margin-left: 15px;
    font-size: 24px;
    color: #fff;
  } */
  
  /* Navbar items */
  .navbar {
    display: flex;
  }
  
  .nav-list {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  .nav-item {
    margin-left: 20px;
  }
  
  .nav-link {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
  }
  
  .nav-link i {
    margin-right: 8px;
  }

    /* margin-top:200px; */
  
  
  /* Hover and active styles */
  .nav-link:hover,
  .nav-link.active {
    color: #f39c12;
  }
   .home-content{
    width: 100%;
    height:auto;
   
    /* background-color:lightgray; */
   }
   .home-content h3{
      padding:20px;
   }

  
 
    .flower-1{
      width:60px;
      /* padding-left:420px;
       */
       /* transform: rotate(-90deg); */
       left:0px;
       top:100px;
       position:relative;
    }
    .flower-2{
      width:120px;
      position:relative;
      top:-90px;
      left:-75px;
    }
    .home-content-sub{
      display:flex;
      padding-left:120px;
      gap:130px;
    }
    .home-content-yellow{
      /* margin-top: 0px; */
      background-color: #FAFA33;
      padding:120px;
     
        opacity: 0; /* Start hidden */
        transition: opacity 1.0s ease-in-out; /* Smooth fade transition */
      }
      
      .home-content-yellow.visible {
        opacity: 1; /* Fully visible */
      }
    
    .home-content-2 h1{
    font-size: 60px; 
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.87);
    height:170px;
    border-radius: 12px;
    padding:30px;
    border:1px solid white;
    /* padding-left: 300px; */
    color:white;
    width:450px;
    }
    .home-content-2 h2{
      align-items: center;
      padding-top: 20px;
      justify-content: center;
    }
    .logo-h1{
      font-size: 25px;
     margin-top: 0px;
      width:500px;
    
      padding-left: 20px;
      font-weight: bold;
      background: linear-gradient(to right, yellow, rgb(255, 51, 0));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
    }
    .logo h1{
      font-size: 25px;
     margin-top: 0px;
      width:500px;
    
      padding-left: 90px;
      font-weight: bold;
      background: linear-gradient(to right, yellow, rgb(255, 51, 0));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
    }
.home-dream-catcher{
  width:330px;
padding-top: 40px;
  padding-left: 130px;
}   .home-content-2{
    display:flex;
    gap:170px;
    padding:80px 0px;
    background-color: #7e84ec;

   }
  /* Responsive design for mobile screens */
@media (max-width: 768px) {
  .parallax-section {
      background-attachment: scroll; /* Disable fixed background on mobile for better performance */
      height: auto; /* Adjust height to content */
  }
  .logo h1{
   display:none;
    
  }
  .content {
      padding: 20px;
      font-size: 18px; /* Decrease font size on mobile */
  }

  .world-content {
      left: 0;
      width: 100%;
      top: -100px;
  }

  .world-content button {
      padding: 15px;
      font-size: 16px; /* Adjust button size */
      width: 100%; /* Make buttons full width */
  }

  .nav {
      flex-direction: column; /* Stack the navigation vertically */
      padding: 10px;
  }
.logo-h1{
  padding:0px;
  position:relative;
  top:17px;
  width:200px;
  left:10px;
  font-size: 22px;
  /* width:120px; */
/* height: 300px; */
  /* margin-bottom:200px; */
}
  .nav-list {
      flex-direction: column; /* Stack nav items */
      margin: 0;
  }

  .nav-item {
      margin: 10px 0;
  }

  .home-content {
      text-align: center;
  }
  .home-content-yellow {
    padding: 40px;
   
}
  .flower-1{
    width: 60px; 
    left: 280px;
    top: 80px;
  }
  .flower-2 {
      width: 80px; /* Smaller flower images */
      left: 0px;
      top: 0;
  }

  .home-content-sub {
      flex-direction: column; /* Stack sub-content */
      padding-left: 0;
      gap: 20px;
  }

  .home-content-2 h1 {
      font-size: 40px;
      width: 80%;
  }
  

  .home-content-2 {
      flex-direction: column;
      gap: 40px;
      padding: 20px;
  }

  .home-dream-catcher {
      width: 200px;
      padding-left: 90px;
  }
}
  