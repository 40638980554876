/* General Styles */
.about-header {
  height: 100px;
  background-color: #7e84ec;
}

.video-section {
  background-color: #FAFA33;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.video-player {
  width: 45%;
  max-width: 500px;
  border-radius: 12px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .video-player {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .video-container {
    flex-direction: column;
    align-items: center;
  }

  .video-player {
    width: 100%;
  }
}
